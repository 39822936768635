<template>
    <div class="min-vh-100 bg-light">
        <div class="d-flex justify-content-center align-items-center m-3">
            <b-row class="app-login-box d-block mx-0">
                <div class="d-flex justify-content-center w-auto">
                    <img src="@/assets/images/logo-login.png" height="100px" class="d-block img-fluid logo-img" />

                </div>
                <div class="mx-auto mb-3"/>
                <div class="modal-dialog w-100 mx-auto form-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="h5 modal-title text-center">
                                <h4 class="mt-2">
                                    <span>Create a new account</span>
                                </h4>
                            </div>
                            <register-form :refferal.sync="referral" />
                        </div>
                    </div>
                </div>
            </b-row>
        </div>
    </div>

</template>
<script>
import RegisterForm from "@/components/UserPages/RegisterForm";

export default {
    components: {
        RegisterForm,
    },
    data: () => ({
        slickOptions6: {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,

            adaptiveHeight: true
        },

        slide: 0,
        sliding: null,
        referral: ''

    }),

    mounted() {
        if (this.$route.params.inviteId) {
            this.referral = this.$route.params.inviteId
        }
    },
    methods: {
        handleClick(newTab) {
            this.currentTab = newTab;
        },
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },

        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    }
};
</script>
