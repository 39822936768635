<template>
    <b-form>
        <b-row v-if="success">
            <b-col md="12">
                <h1>Check your email inbox and confirm your email address by clicking on the link in the email.</h1>
                
            </b-col>
            <b-col md="6">
                <h2>Please, check your Spam inbox, too.</h2>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col md="12" v-if="error">
                {{error}}
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="First name"
                >
                    <b-form-input id="firstName" type="text" name="firstName" placeholder="First name" v-model="form.firstName" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Last name"
                >
                    <b-form-input id="lastName" type="text" name="lastName" placeholder="Last name" v-model="form.lastName" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Email address"
                >
                    <b-form-input id="email" type="email" name="email" placeholder="Email" v-model="form.email" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Username"
                >
                    <b-form-input id="username" type="text" name="username" placeholder="Username" v-model="form.username" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Invite ID"
                >
                    <b-form-input id="inviteId" type="text" name="inviteId"  v-model="refferal" disabled />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Business partner"
                >
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.business"
                        name="checkbox-1"
                    >
                        I want to add my business to Hotelium Partners.
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Enter new password"
                    description="Your password should contain at least 6 characters"
                >
                    <b-form-input id="password-1" type="password" name="password-1" placeholder="Password" v-model="form.password" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Confirm new password"
                >
                    <b-form-input id="password-1" type="password" name="password-1" placeholder="Repeat password" v-model="password2" required v-validate="'required|confirmed:password1'" />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                >
                    <b-form-checkbox required v-model="terms">I agree to the <a :href='baseURL+"/static/materials/Hotelium%20TERMS%20AND%20CONDITIONS%202024.pdf"'>terms and conditions</a> and <a :href='baseURL+"/static/materials/Line_of_sponsorship_rules.pdf"'>line of sponsorship rules</a>.</b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col md="8" v-if="error">
                <b-form-group>
                    <div class="alert alert-danger" role="alert">
                        {{error}}
                    </div>
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group>
                    <div class="ml-auto">
                        <v-btn color="primary" class="text-unset" @click="onSubmit">Create account</v-btn>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import gql from "graphql-tag";
import * as EmailValidator from 'email-validator';

export default {
    name: "RegisterForm",
    props: [
        "refferal"
    ],
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                business: false,
                username: "",
                password: "",
            },
            password2: "",
            terms: false,
            error: null,
            success: false
        }
    },
    mounted() {
    },
    computed: {

        buttonAllow() {
            return this.terms && this.form.firstName.length > 0 && this.form.lastName.length > 0
                && EmailValidator.validate(this.form.email) && this.form.username.length > 5
                && this.form.password === this.password2 && this.form.password.length > 5
        },
    },
    methods: {
        onSubmit() {
            this.error = null
            if (!this.terms) this.error = "You have to agree with terms and conditions"
            if (!this.form.firstName) this.error = "Missing first name"
            if (!this.form.lastName) this.error = "Missing last name"
            if (!this.form.email) this.error = "Missing email address"
            if (!this.form.username) this.error = "Missing username"
            if (!this.form.password) this.error = "Missing password"
            if (this.form.password !== this.password2) this.error = "Passwords do not match"
            if (this.error) return

            this.$apollo.mutate({
                mutation: gql`mutation($inviteId:ID!,$input:CreateUserInput!){createUserByInvite(inviteId:$inviteId,input:$input){error,token,user{id,username,email,firstName}}}`,
                variables: {

                    inviteId: this.refferal,
                    input: this.form,
                }
            }).then((res) => {
                if (res.data.createUserByInvite.error) {
                    if (res.data.createUserByInvite.error === "CONFIRM_EMAIL") {
                        this.success = true
                        return
                    }
                    this.error = res.data.createUserByInvite.error
                    this.form.password = ""
                    this.password2 = ""
                    this.nowtime = new Date().getTime()
                }
            })
        }
    }
}
</script>

<style scoped>

</style>